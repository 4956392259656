@import "../../sass/vars.module.scss";

.PageContainer {
  width: 100%;
  height: 100%; // padding: 0 40px;
  position: relative;
  margin: 0 auto;
  // background-color: black;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 20px;
  overflow: hidden;
  max-width: 700px;

  // &.-feather-{
  //   &::after{
  //     content: '';
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
    
  //     top: 0;
  //     left: 0;
  //     // box-shadow: inset 0 0 18px $BG_YELLOW;
  //     box-shadow: inset 0 0 18px 14px $BG_YELLOW;
  //   }
  // }

 

  @media screen and (min-width: 700px) {

  .featherRight, 
  .featherLeft
   {
    position: absolute; 
    z-index: 1;
  }

  

  .featherRight{
    top: 0;
    right: 0;
    height: 100%;
    width: 2rem;
    background: linear-gradient(90deg, $BG_YELLOW_0, $BG_YELLOW);
  } 
 

  .featherLeft {
    top: 0;
    left: 0;
    height: 100%;
    width: 2rem;
    background: linear-gradient(90deg, $BG_YELLOW, $BG_YELLOW_0);
  }
  }

  
}

// @media screen and (min-width: 480px) {
//   .PageContainer {
//     max-width: 480px;
//   }
// }

// @media screen and (min-width: 760px) {
//   .PageContainer {
//     max-width: 640px;
//   }
// }

// @media screen and (min-width: 990px) {
//   .PageContainer {
//     max-width: 990px;
//   }
// }
