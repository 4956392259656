@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;700&family=Baloo+Tamma+2&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;500;700&display=swap');
@import "./sass/vars.module.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}



input,
textarea,

button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

html{
    font-size: 10px;
    font-family: 'Baloo 2', 'Baloo Tamma 2' !important;

    a{
        color: unset !important;
        text-decoration: none !important;
    }
    
}

.h1{ @include h1() }

.h2{ @include h2() }

.h3{ @include h3() }

.h4{ @include h4() }

.h5{ @include h5() }

.p{ @include p() }

.a{ @include a() }

.a.-danger-{
    color: $danger !important;
    &:hover{ color: $danger !important}
}

.bold{ @include bold()}

.featherTop,
.featherBottom {
  position: fixed; 
  z-index: 1;
}

.featherTop{
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background: linear-gradient( $BG_YELLOW_0, $BG_YELLOW_80, $BG_YELLOW );
} 

.featherBottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background: linear-gradient(0deg, $BG_YELLOW, $BG_YELLOW_80, $BG_YELLOW_0);
}

