@import "../../sass/vars.module.scss";

.Button {
  width: 100%;
  // height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  a {
    text-decoration: none;
    color: black;
    font-size: 14px;
    text-align: center;
  }
}

.iconButton {
  border: 1.5px solid $darkGrey;
  background-color: $none;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition(background-color);
  cursor: pointer;

  &:hover {
    background-color: $yellow;
  }

  &.-disabled- {
    background-color: $lightGrey;
    border-color: $lightGrey;

    &.hover {
      background-color: $lightGrey;
      border-color: $lightGrey;
    }
  }
}

.invAlert {
  position: absolute;
  bottom: -20px;
  right: 0;
  opacity: 0;
  color: $WHITE;
  background-color: $green;
  padding: 2px 10px;
  border-radius: 4px;
  width: max-content;
  transform-origin: 80% -8px;
  z-index: 100;

  // &::before {
  //   position: absolute;
  //   top: -8px;
  //   left: 80%;
  //   width: 5px;
  //   height: 5px;
  //   content: '';
  //   background-color: #f0f;
  //   border-radius: 50%;
  //   transform: translate(-50%, -50%);
  // }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 2px;
    transform: translate(-100%, -100%);
    border-style: solid;
    border-color: transparent transparent $green transparent;
    border-width: 0px 6px 8px 6px;
    // z-index: 1000;
  }

  &.-blue-{
    background-color: $CYAN;
    &::after{
      border-color: transparent transparent $CYAN transparent;  
    }
  }

  &.-orange-{
    background-color: $ORANGE;
    &::after{
      border-color: transparent transparent $ORANGE transparent;  
    }
  }
}
