//important to name this file *.module.scss
//else the export wont work in CRA 4.

$lightYellow: #f6f7f0;
$yellow: #eff7c3;
$scrollCaretColor: #383838;
$namedConvText: #7bbce0;
$white: #fff;
$darkGrey: #383838;
$midGrey: #b3b3b3;
$lightGrey: #c9c9c9;
$extraLightGrey : rgb(218, 218, 218);
$blue: #7bbce0;
$lightBlue: #b9ceda;
$green: #a2e07b;
$lightGreen: #c0eca4;
$none: rgba(0,0,0,0);
$gridGap : 10px;
$pageMarginHor: 20;
$pageMarginVer: 60;
$pageMarginBot: 60;
$none: rgba(0, 0, 0, 0);
$choiceBackground: #e8ecf3;
$choiceBtnStroke: #526b9e;
$choiceBtnSelected: #9fbeff;
$emailFormatBackground: #e5e5e5;
$black: #000;
$disabled: #c6c6c6;
$active: #08bd80;
$danger:#f6101a;
$dangerLight:#ffcccf;
$textColor-disabled: #898989;
$BG_YELLOW: #f6f7f0;
$BG_YELLOW_0: #f6f7f000;
$BG_YELLOW_80: #f6f7f0cc;
$CYAN:#4DBCFF;
$ORANGE: #E7935D;
$WHITE: #fff;


strong{
  font-weight: bold;
}

/* LOCKED */
@mixin h1() {
  font-size: 4.2rem;
  line-height: 1.4em;
  font-weight: normal;

  @media screen and (min-width: 760px) {
    font-size: 3.8rem;
  }
}

.qag-markdownStyles h1{
  @include h1();
}


/* LOCKED */
@mixin h2() {
  font-size: 3rem;
  line-height: 1.4em;
  font-weight: normal;

  @media screen and (min-width: 760px) {
    font-size: 3.4rem;
  }
}

.qag-markdownStyles h2{
  @include h2();
}



@mixin h3() {
  font-size: 2.4rem;
  line-height: 1.4em;
  font-weight: normal;

  @media screen and (min-width: 760px) {
    font-size: 3.0rem;
  }
}
.qag-markdownStyles h3{
  @include h3();
}


/* LOCKED */
@mixin h4() {
  font-size: 1.6rem;
  line-height: 1.4em;
  font-weight: normal;

  @media screen and (min-width: 760px) {
    font-size: 2.4rem;
  }
}
.qag-markdownStyles h4{
  @include h4();
}

/* LOCKED */
@mixin h5() {
  font-size: 1.2rem;
  line-height: 1.3em;
  font-weight: normal;

  @media screen and (min-width: 760px) {
    font-size: 1.4rem;
  }
}
.qag-markdownStyles h5{
  @include h5();
}

/* LOCKED */
@mixin p() {
  font-size: 1.4rem;
  line-height: 1.5em;
  font-weight: normal;

  @media screen and (min-width: 760px) {
    font-size: 1.8rem;
  }
}

.qag-markdownStyles p{
  @include p();
}

@mixin a() {
  color: blue !important;
  text-decoration: underline !important;
  cursor: pointer;
  background-color: $none;
  border: none;

  &:hover{
    color: rgb(65, 65, 255) !important
  }
}

.qag-markdownStyles a{
  @include a();
}

.qag-markdownStyles img{
  width: 25rem;
}



@mixin bold () {
  font-weight: bold;
}



@mixin transition($property) {
  transition: $property 0.3s ease-out;
}

:export {
  yellow: $yellow;
  lightYellow: $lightYellow;

  scrollCaretColor: $scrollCaretColor;

  darkGrey : $darkGrey;
  midGrey : $midGrey;
  lightGrey : $lightGrey;
  extraLightGrey: $extraLightGrey;
  namedConvText: $namedConvText;
  blue: $blue;
  lightBlue: $lightBlue;
  green: $green;
  lightGreen: $lightGreen;
  white: $white;
  none: $none;
  pageMarginHor: $pageMarginHor;
  pageMarginVer: $pageMarginVer;
  gridGap : $gridGap;
  choiceBackground: $choiceBackground;
  danger : $danger;
  dangerLight : $dangerLight;
  pageMarginBot : $pageMarginBot
}
