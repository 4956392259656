@import "../../sass/vars.module.scss";

.Metrics {
  // position: fixed;
  // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  // height: 4rem;
  // background-color: $white;
  // z-index: 10;

  // // margin: 0 auto;
 
  // // margin-top: -1.6rem;
  display: flex;
  
  // justify-content: space-between;
  // align-items: center;
}

.MetricBlock {
  flex: 1 0 33%;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 4rem;
  // justify-content: center;
  // align-items: center;
  padding: 0 1rem;
}
