.NetworkGraph{

    > svg{
        transition: transform 2s ease-out, opacity 1s ease-out;
        transform: translateX(0);
        transform-origin: left center;
        
        
    }
    
}
