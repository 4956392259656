.grid {
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(10, 1fr);
  min-height: calc(100vh - 120px);
}

.qagGrid--fixed {
  height: calc(100vh - 120px);
  grid-template-rows: repeat(20, 1fr);
}

.qagGrid--auto {
  grid-template-rows: auto;
  font-size: 0;
}

.qagGrid--flexible {
  // grid-template-rows: repeat(20, 15.2px) auto;
  // min-height: calc(100vh - 120px);
}

.colGrid{
  width: 100%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(10, 1fr);
}

// .page-background {
//   grid-column: 1 / span 10;
//   grid-row: 5 / span 10;
//   max-width: 100%;
//   max-height: 100%;
//   margin: 0 auto;
// }
